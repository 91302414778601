import type { RegionalLanguageCode } from "@prisma/client";
import type { SerializeFrom } from "@remix-run/node";

import { useRouteLoaderData } from "@remix-run/react";

import { type loader as rootLoader } from "~/root";

function isUser(
  user: unknown,
): user is SerializeFrom<typeof rootLoader>["user"] {
  return (
    user != null &&
    typeof user === "object" &&
    "id" in user &&
    typeof user.id === "string"
  );
}

function isTeam(
  team: unknown,
): team is SerializeFrom<typeof rootLoader>["currentTeam"] {
  return (
    team != null &&
    typeof team === "object" &&
    "id" in team &&
    typeof team.id === "string"
  );
}

function isTeams(
  teams: unknown,
): teams is SerializeFrom<typeof rootLoader>["otherTeams"] {
  return Array.isArray(teams) && teams.every(isTeam);
}

export function useOptionalUser() {
  const data = useRouteLoaderData<typeof rootLoader>("root");
  if (data == null || !isUser(data.user)) {
    return undefined;
  }
  return data.user;
}

export function useCurrentTeam() {
  const data = useRouteLoaderData<typeof rootLoader>("root");
  if (data == null || !isTeam(data.currentTeam)) {
    return undefined;
  }
  return data.currentTeam;
}

export function useOtherTeams() {
  const data = useRouteLoaderData<typeof rootLoader>("root");
  if (data == null || !isTeams(data.otherTeams)) {
    return undefined;
  }
  return data.otherTeams;
}

export function metaTitle(title: string) {
  return `${title} | Conveo`;
}

export async function blobFetcher(
  input: RequestInfo,
  init?: RequestInit,
): Promise<Blob> {
  const res = await fetch(input, init);

  if (!res.ok) {
    const text = await res.text();
    let errorMessage = "Failed to generate TTS audio";
    try {
      const json = JSON.parse(text);
      if (json.error != null) {
        errorMessage = json.error;
      }
    } catch (e) {
      // If parsing as JSON fails, use the text as the error message
      errorMessage = text;
    }
    const error = new Error(errorMessage) as {
      status: number;
    } & Error;
    error.status = res.status;
    throw error;
  }

  return res.blob();
}

// Only allow relative paths
// Throws an error if the path is not valid
export function validateRedirect(path: null | string) {
  if (path == null) {
    return true;
  }
  // Check if the path is an absolute URL
  // This regex checks for the presence of a scheme (like http://, https://, ftp://, etc.)
  const isAbsoluteUrl = /^[a-zA-Z][a-zA-Z\d+\-.]*:/.test(path);
  if (isAbsoluteUrl) {
    throw new Error("Invalid redirect path");
  }

  // Check if the path is protocol-relative (starts with //)
  const isProtocolRelative = path.startsWith("//");
  if (isProtocolRelative) {
    throw new Error("Invalid redirect path");
  }
  return true;
}

export function formatTime(totalSeconds: number): string {
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;
  if (minutes === 0) {
    return `${seconds.toString().padStart(2, "0")}s`;
  } else {
    return `${minutes.toString().padStart(2, "0")}m ${seconds.toString().padStart(2, "0")}s`;
  }
}

export const languages = [
  "ar_EG",
  "bs_BA",
  "bg_BG",
  "hr_HR",
  "cs_CZ",
  "da_DK",
  "nl_NL",
  "nl_BE",
  "en_US",
  "et_EE",
  "fi_FI",
  "fr_FR",
  "de_DE",
  "el_GR",
  "hi_IN",
  "hu_HU",
  "is_IS",
  "it_IT",
  "lv_LV",
  "lt_LT",
  "mk_MK",
  "pl_PL",
  "pt_PT",
  "ro_RO",
  "ru_RU",
  "sr_RS",
  "sk_SK",
  "sl_SI",
  "es_ES",
  "sv_SE",
  "tr_TR",
  "uk_UA",
] satisfies RegionalLanguageCode[];
